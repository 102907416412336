import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Collapse,
  Row,
  Form,
  Button,
  Modal,
  Radio,
  Input,
  message,
  Popover,
  Typography,
} from "antd";
import DashboardTableCard from "src/components/DashboardTableCard";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/state/app.model";
import { getStudentAdmissionDetailsById } from "src/state/student/student.action";
import { useAppSelector } from "src/state/app.hooks";
import { studentSelector } from "src/state/student/student.reducer";
import { config } from "src/utils";
import { getFessDetailsById } from "src/state/feesDetails/fees.action";
import { feesSelector } from "src/state/feesDetails/fees.reducer";
import { getCourseByAdmissionId } from "src/state/course/course.action";
import { courseSelector } from "src/state/course/course.reducer";
import { InboxOutlined } from "@ant-design/icons";
import {
  BatchFacultyAttendance,
  BatchSingingSheet,
  BatchStudentAttendance,
  BatchStudentMarks,
  IGetBatchData,
  getBatchByAdmissionIdResponse,
  BatchDataItem,
} from "src/services/Batch/batch.model";
import useLazyService from "src/state/hooks/useLazyService.hook";
import BatchService from "src/services/Batch/batch.service";
import { editFeedBackDataById } from "src/state/feedBack/feedBack.action";
import FloatLabel from "src/components/Form/FloatLabel";
import { IEditFeedBack } from "src/services/feedBack/feedBack.model";
import {
  AdmisionStatusType,
  AdmissionSubCourseStatus,
  CourseCategoryStatusType,
  TopicType,
  UserBatchStatusType,
} from "src/utils/constants/constant";
import {
  clearRemoveMessage,
  feedBackSelector,
} from "src/state/feedBack/feedBack.reducer";
import {
  currencyFormat,
  dateFormate,
  GetSortOrder,
  openInNewTab,
  trimObject,
} from "src/utils/helperFunction";
import { IStudentList } from "src/services/student/student.model";
import moment from "moment";
import { loginSelector, setIsLogIn } from "src/state/Login/login.reducer";
import SubmissionForm from "./SubmissionForm/SubmissionForm";
import ModalComponent from "src/components/Modal";
import { updateStudentSubmissionLink } from "src/state/batch/batch.action";
import {
  ISingingSheetData,
  ISubmitFormData,
} from "./SubmissionForm/SubmissionForm.model";
import {
  AdmissionSubcourse,
  getCourseByAdmissionIdResponse,
} from "src/services/Course/course.model";
import MoreButtonShow from "src/components/MoreButton";
import {
  getExamPaperDetails,
  submitExamPaperStudentAnswer,
} from "src/state/exam/exam.action";
import { studentExamSelector } from "src/state/exam/exam.reducer";
import { IExamPaperDetails } from "src/services/exam/exam.model";
import { EyeOutlined } from "@ant-design/icons";
const { Panel } = Collapse;
const { Text } = Typography;

const StudentDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const loginState = useAppSelector(loginSelector);
  const studentState = useAppSelector(studentSelector);
  const studentExamState = useAppSelector(studentExamSelector);
  const feesState = useAppSelector(feesSelector);
  const courseState = useAppSelector(courseSelector);
  const feedbackState = useAppSelector(feedBackSelector);
  const [batchState, setBatchState] = useState<IGetBatchData[]>([]);
  const [topicId, setTopicId] = useState<number>();
  const [batchId, setBatchId] = useState<number>();
  const [topicData, setTopicData] = useState<BatchSingingSheet>();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmissionModel, setIsSubmissionModel] = useState(false);
  const [studentData, setStudentData] = useState<BatchStudentMarks>();
  const [initialValue, setInitialValue] = useState<ISubmitFormData>();
  const [examPaperData, setExamPaperData] = useState<IExamPaperDetails[]>([]);
  const [examPortalUrl] = studentState.getById.data.map(
    (studentData: IStudentList) => {
      return studentData.branch.examportal_url;
    }
  );
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState<string | null>(null);
  const [remarksModalOpen, setRemarksModalOpen] = useState(false);
  const [showRemark, setShowRemarks] = useState<any>("");

  const latestActualDate = topicData?.batchFacultyAttendances?.reduce(
    (maxDate: Date, attendanceData: BatchFacultyAttendance) => {
      const currentDate = new Date(attendanceData?.actual_date);
      return currentDate > maxDate ? currentDate : maxDate;
    },
    new Date(0)
  );
  const rules = {
    feedback: [{ required: true, message: "Please Select feedback" }],
  };

  const { data, refetch } = useLazyService(
    BatchService.getSigningSheetByBatchId,
    "id"
  );

  const { data: batchData, refetch: getBatchByAdmissionId } = useLazyService(
    BatchService.getBatchByAdmissionId,
    "id"
  );

  useEffect(() => {
    setExamPaperData(studentExamState.examPaperData.data);
  }, [studentExamState.examPaperData.data]);
  useEffect(() => {
    if (feedbackState.addFeedBack.message) {
      if (feedbackState.addFeedBack.hasErrors) {
        message.error(feedbackState.addFeedBack.message);
      } else {
        message.success(feedbackState.addFeedBack.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [feedbackState.addFeedBack.message]);

  useEffect(() => {
    if (
      studentState.getById.hasErrors &&
      studentState.getById.message === "You have not access this record!"
    ) {
      dispatch(setIsLogIn(false));
      navigate("/", { replace: true });
    }
  }, [studentState.getById]);

  const isStudentMobileNumber = () => {
    const loginMobileNumber = loginState?.verifyData?.data?.mobile_no;
    const studentData = studentState?.getById?.data[0];
    const fatherMobileNumber = studentData.father_mobile_no;
    const motherMobileNumber = studentData.mother_mobile_no;

    return !(
      loginMobileNumber === fatherMobileNumber ||
      loginMobileNumber === motherMobileNumber
    );
  };

  useEffect(() => {
    dispatch(getStudentAdmissionDetailsById(Number(id)));
    dispatch(getFessDetailsById(Number(id)));
    dispatch(getCourseByAdmissionId(Number(id)));
  }, []);

  const handleSubmit = (
    id: number,
    batch_singing_sheet_id: number,
    batchSingingSheets: BatchSingingSheet[]
  ) => {
    const batchSingingSheetData = batchSingingSheets.find(
      (batchSingingSheet: BatchSingingSheet) =>
        batchSingingSheet.id === batch_singing_sheet_id
    );
    setTopicId(id);
    setTopicData(batchSingingSheetData);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  const onFinish = (value: IEditFeedBack) => {
    Object.assign(value, { id: topicId });
    dispatch(editFeedBackDataById(value)).then((res: any) => {
      form.resetFields();
      setIsModalOpen(false);
      setBatchState((prev: IGetBatchData[]) => {
        prev?.map((batch: IGetBatchData) => {
          batch.data?.map((batchAdmission: getBatchByAdmissionIdResponse) => {
            batchAdmission.batchSingingSheets &&
              batchAdmission.batchSingingSheets?.map(
                (batchSheet: BatchSingingSheet) => {
                  batchSheet.batchFacultyAttendances?.map(
                    (batchAttendance: BatchFacultyAttendance) => {
                      batchAttendance.batchStudentAttendances?.map(
                        (
                          batchStudentAttendanceData: BatchStudentAttendance
                        ) => {
                          if (
                            batchStudentAttendanceData.id === res.meta.arg.id
                          ) {
                            batchStudentAttendanceData.feedback =
                              res.meta.arg.feedback;
                          }
                        }
                      );
                    }
                  );
                }
              );
          });
        });
        return prev;
      });
    });
  };

  const batchsign = (batchId: number) => {
    if (batchId) {
      getBatchByAdmissionId(id);
      setBatchId(batchId);
      dispatch(
        getExamPaperDetails({ admissionId: Number(id), batch_id: batchId })
      );
      refetch(batchId).then((res: any) => {
        if (batchState.length === 0) {
          setBatchState([{ id: batchId, data: res.data }]);
        } else {
          setBatchState((prev: IGetBatchData[]) => {
            const batchIds = prev?.map((batchData: IGetBatchData) => {
              return batchData.id;
            });
            let data = prev;
            if (batchIds.length > 0 && !batchIds.includes(batchId)) {
              data = [...data, { id: batchId, data: res.data }];
            }
            return data;
          });
        }
      });
    }
  };

  const sortedData = feesState.getById.data?.slice()?.sort((a, b) => {
    return Number(a.installment_no) - Number(b.installment_no);
  });

  const showMarks = (data: getBatchByAdmissionIdResponse) => {
    switch (data.type) {
      case TopicType.LECTURE:
      case TopicType.PROJECT:
      case TopicType.VIVA:
      case TopicType.EXAM_PRACTICAL:
      case TopicType.EXAM_THEORY:
        const filterData = data?.Batch_student_marks?.filter(
          (f: BatchStudentMarks) => Number(f.admission_id) === Number(id)
        );
        const marksShow =
          filterData &&
          filterData.map((studentMarks) => {
            const marksDecimalValue =
              studentMarks.marks - Math.floor(studentMarks.marks);
            if (studentMarks.marks >= 0 && studentMarks.marks !== null) {
              return (
                <span className="feedback-mark">{`${
                  marksDecimalValue > 0
                    ? studentMarks.marks
                    : Math.round(studentMarks.marks)
                } /${studentMarks.total_marks}`}</span>
              );
            }
          });
        return marksShow;
      default:
        return <span></span>;
    }
  };

  const examClick = (
    facultyAttendanceId: number,
    batchShiningSheetId: number
  ) => {
    const examPaperRecord = examPaperData.find((item) => {
      return (
        item.batch_faculty_attendence_id === facultyAttendanceId &&
        item.batch_singing_sheet_id === batchShiningSheetId
      );
    });
    const data = {
      start_time: examPaperRecord?.start_time.replace(".", ":"),
      created_date: examPaperRecord?.created_date,
      id: examPaperRecord?.id,
      exam_hour_time: examPaperRecord?.exam_time_hours,
      exam_paper_student_id: examPaperRecord?.exam_paper_student_id,
      exam_type: examPaperRecord?.exam_type,
      admission_id: Number(id),
      batch_id: batchId,
    };
    const url = `exam-portal?data=${encodeURIComponent(JSON.stringify(data))}`;
    const popup = window.open(
      url,
      "popup",
      "width=" +
        window.screen.availWidth +
        ",height=" +
        window.screen.availHeight
    );
    if (popup) {
      popup.onload = () => {
        // Disable F12
        popup.document.addEventListener("keydown", function (e) {
          if (e.key === "F12") {
            e.preventDefault();
            return false;
          }
        });

        // Disable right click
        popup.document.addEventListener("contextmenu", (e) => {
          e.preventDefault();
          return false;
        });

        const closePopupAndHandleExam = async () => {
          popup.close();
          const currentUrl = new URL(url, window.location.origin);
          if (examPaperRecord && popup.location.search !== currentUrl?.search) {
            const studentExamAmberdata = {
              exam_paper_student_id: examPaperRecord?.exam_paper_student_id,
              exam_student_data: [],
              exam_paper_id: examPaperRecord?.id,
            };
            await dispatch(submitExamPaperStudentAnswer(studentExamAmberdata));
          }
          if (batchId) {
            await dispatch(
              getExamPaperDetails({
                admissionId: Number(id),
                batch_id: batchId,
              })
            );
          }
        };
        // Close popup when tab is closed
        const visibilityChangeListener = async () => {
          if (
            document.visibilityState === "hidden" ||
            popup.document.visibilityState === "hidden"
          ) {
            await closePopupAndHandleExam();
          }
        };

        popup.addEventListener("beforeunload", closePopupAndHandleExam);
        if (examPaperRecord?.exam_type === TopicType.EXAM_THEORY) {
          popup.document.addEventListener(
            "visibilitychange",
            visibilityChangeListener
          );
          popup.addEventListener("blur", visibilityChangeListener);
          window.addEventListener("blur", visibilityChangeListener);

          popup.addEventListener("beforeunload", () => {
            document.removeEventListener(
              "visibilitychange",
              visibilityChangeListener
            );
          });

          const blurListener = async () => {
            if (!popup.document.hasFocus()) {
              await closePopupAndHandleExam();
            }
          };

          const focusListener = async () => {
            if (!popup.document.hasFocus()) {
              await closePopupAndHandleExam();
            }
          };

          window.addEventListener("blur", blurListener);
          window.addEventListener("focus", focusListener);
          popup.addEventListener("blur", blurListener);

          // Prevent text selection
          popup.document.addEventListener("selectstart", (e) => {
            e.preventDefault();
            return false;
          });

          // Prevent paste
          popup.document.addEventListener("paste", (e) => {
            e.preventDefault();
            return false;
          });
        }
      };
    }
  };

  const feedbackOrMarks = (
    data: getBatchByAdmissionIdResponse,
    singingSheetId: number,
    facultyAttendanceId: number
  ) => {
    const filterData = data?.Batch_student_marks?.filter(
      (studentMarksData: BatchStudentMarks) =>
        Number(studentMarksData.admission_id) === Number(id)
    );
    const singingSheetData = data.batchSingingSheets.find(
      (singingSheet: BatchSingingSheet) => singingSheet.id === singingSheetId
    );

    if (singingSheetData) {
      const attendanceData = singingSheetData.batchFacultyAttendances.find(
        (attendance: BatchFacultyAttendance) =>
          attendance.id === facultyAttendanceId
      );
      const isEligibleForExam = examPaperData.some(
        (item: IExamPaperDetails) => {
          return (
            item.batch_faculty_attendence_id === attendanceData?.id &&
            item.batch_singing_sheet_id === data.id
          );
        }
      );
      if (
        attendanceData &&
        (data.type === TopicType.EXAM_THEORY ||
          data.type === TopicType.EXAM_PRACTICAL) &&
        isEligibleForExam &&
        !(
          loginState?.verifyData?.data?.mobile_no ===
            studentState?.getById?.data[0]?.father_mobile_no ||
          loginState?.verifyData?.data?.mobile_no ===
            studentState?.getById?.data[0]?.mother_mobile_no
        )
      ) {
        return (
          <tr>
            <td>
              <button
                className="submit-btn"
                style={{
                  display: "inline-block",
                  background: "transparent",
                  border: "0",
                  color: "#f44336",
                  borderBottom: "1px solid #f44336",
                }}
                onClick={() => examClick(attendanceData.id, data?.id)}
              >
                Exam
              </button>
            </td>
          </tr>
        );
      }
      if (
        attendanceData &&
        attendanceData.batchStudentAttendances[0].is_present === false
      ) {
        return (
          <tr>
            <td>
              <span className="absent-data">Absent</span>
            </td>
          </tr>
        );
      } else if (
        attendanceData &&
        attendanceData.batchStudentAttendances[0]?.is_present === true &&
        attendanceData.batchStudentAttendances[0]?.feedback != null &&
        data.type === TopicType.LECTURE
      ) {
        return (
          <tr>
            <td>
              <span
                className={`${"feedback"} ${
                  attendanceData.batchStudentAttendances[0]?.feedback
                }`}
              >
                {attendanceData.batchStudentAttendances[0]?.feedback}
              </span>
            </td>
          </tr>
        );
      } else if (
        attendanceData &&
        attendanceData.batchStudentAttendances[0]?.is_present === true &&
        attendanceData.batchStudentAttendances[0]?.feedback === null &&
        data.type === TopicType.LECTURE &&
        !(
          loginState?.verifyData?.data?.mobile_no ===
            studentState?.getById?.data[0]?.father_mobile_no ||
          loginState?.verifyData?.data?.mobile_no ===
            studentState?.getById?.data[0]?.mother_mobile_no
        )
      ) {
        return (
          <tr>
            <td>
              <button
                className="submit-btn"
                style={{
                  display: "inline-block",
                  padding: "3px",
                  background: "transparent",
                  border: "0",
                  color: "#f44336",
                  borderBottom: "1px solid #f44336",
                  paddingBottom: "0",
                }}
                disabled={
                  attendanceData.batchStudentAttendances[0].admission.status ===
                    AdmisionStatusType.CANCELLED ||
                  attendanceData.batchStudentAttendances[0].admission.status ===
                    AdmisionStatusType.TERMINATED
                    ? true
                    : false
                }
                onClick={() =>
                  handleSubmit(
                    attendanceData.batchStudentAttendances[0]?.id,
                    attendanceData.batchStudentAttendances[0]
                      ?.batch_singing_sheet_id,
                    data?.batchSingingSheets
                  )
                }
              >
                Submit
              </button>
            </td>
          </tr>
        );
      } else if (
        filterData &&
        data.type !== TopicType.LECTURE &&
        data.type !== TopicType.EXAM_THEORY &&
        filterData[0]?.marks === null
      ) {
        return (
          <tr>
            <td>-</td>
          </tr>
        );
      } else if (
        filterData &&
        data.type === TopicType.EXAM_THEORY &&
        batchData?.data[0]?.examportal_branch_id !== null &&
        filterData[0]?.marks === null &&
        filterData[0]?.examportal_test_id === null &&
        filterData[0]?.examportal_test_token === null
      ) {
        return (
          <tr
            className="gx-text-center"
            style={{
              color: "#ea5455",
            }}
          >
            <i className="fa fa-refresh"></i>
            <div>On Progress</div>
          </tr>
        );
      } else if (
        filterData &&
        data.type === TopicType.EXAM_THEORY &&
        batchData?.data[0]?.examportal_branch_id != null &&
        filterData[0]?.marks === null &&
        filterData[0]?.examportal_test_id != null &&
        filterData[0]?.examportal_test_token != null
      ) {
        return (
          <tr
            style={{
              color: "#ea5455",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() =>
              openInNewTab(
                `${examPortalUrl}/student/test?id=${filterData[0]?.examportal_test_id}&token=${filterData[0]?.examportal_test_token}`
              )
            }
          >
            Attend Exam
          </tr>
        );
      }
    }
    return null;
  };

  const submissionLinkModel = (data: BatchStudentMarks[]) => {
    const currentStudentMarkData = data?.find(
      (studentMarkData: BatchStudentMarks) =>
        studentMarkData?.admission_id === Number(id)
    );
    setStudentData(currentStudentMarkData);
    setIsSubmissionModel(true);
    if (batchState) {
      let initialData;
      batchState?.forEach((batchData: IGetBatchData) => {
        if (batchData?.id === currentStudentMarkData?.batch_id) {
          const selectedStudentMarkData = batchData?.data
            ?.filter(
              (shiningSheetTopic) =>
                shiningSheetTopic?.type === TopicType?.PROJECT ||
                shiningSheetTopic?.type === TopicType?.EXAM_PRACTICAL
            )
            ?.find(
              (shiningSheetTopic) =>
                shiningSheetTopic?.id ===
                currentStudentMarkData?.batch_singing_sheet_id
            )
            ?.Batch_student_marks?.find(
              (markData: BatchStudentMarks) =>
                markData?.admission_id === Number(id)
            );

          initialData = {
            submission_link: selectedStudentMarkData?.submission_link
              ? selectedStudentMarkData?.submission_link
              : undefined,
          };
        }
      });

      setInitialValue(initialData);
    }
  };

  const onSubmitForm = (value: ISubmitFormData) => {
    const finalValue = { ...value, id: studentData?.id };
    dispatch(updateStudentSubmissionLink(trimObject(finalValue)))?.then(
      (res: any) => {
        if (res?.payload) {
          setIsSubmissionModel(false);
          message.success(res?.payload?.message);
          refetch(studentData?.batch_id).then((res) => {
            const updatedBatchState = batchState?.map(
              (batchData: IGetBatchData) => {
                if (batchData?.id === studentData?.batch_id) {
                  return {
                    ...batchData,
                    data: res?.data?.sort(
                      (
                        prevTopic: BatchStudentMarks,
                        nextTopic: BatchStudentMarks
                      ) => prevTopic?.id - nextTopic?.id
                    ),
                  };
                } else {
                  return batchData;
                }
              }
            );
            setBatchState(updatedBatchState);
          });
        } else {
          if (res?.error) {
            message.error(res?.error?.message);
          }
        }
      }
    );
  };

  const isCareerCourse = (studentData: IStudentList) => {
    if (studentData?.course_category === CourseCategoryStatusType.PACKAGE) {
      return studentData?.package?.career_course;
    } else {
      return studentData?.admission_subcourses?.some(
        (subCourseData) => subCourseData?.subcourse?.career_course
      );
    }
  };

  const handlePanelChange = (key: string | string[]) => {
    setActiveKey(Array.isArray(key) ? key[0] : key);
  };

  return (
    <>
      <div className="main-data">
        {studentState.getById.data
          ?.filter((data) => data.id === Number(id))
          .map((student) => (
            <div key={student?.gr_id}>
              <Row>
                <Col xs={24} xl={12}>
                  <DashboardTableCard
                    image_path={
                      student?.admission_documents?.[0]?.photos
                        ? config.bucket_name +
                          "/" +
                          student?.admission_documents?.[0]?.photos
                        : ""
                    }
                    student_name={`${student?.first_name} ${student?.last_name}`}
                    gr_id={student?.gr_id}
                    email={student?.email}
                    status={student?.status}
                    enrollment_number={student?.enrollment_number}
                    careerCourse={!!isCareerCourse(student)}
                    fees={Number(student?.admission_amount)}
                    course={
                      student?.course_category ===
                      CourseCategoryStatusType.SINGLE
                        ? student?.admission_subcourses[0]?.subcourse?.name
                        : student?.package?.name
                    }
                    student_performance={student?.student_performance}
                    package_id={student?.package_id}
                  />
                </Col>
              </Row>
            </div>
          ))}
        <Row gutter={15}>
          <Col xs={24} xl={12}>
            <Card className="student-details gx-mt-2">
              <Collapse defaultActiveKey={["1"]}>
                <Panel header="Course" key="1">
                  <div className="course-name">
                    <div className="course-info">
                      <>
                        <h4>Description for feedback</h4>
                        <hr />
                        <div className="gx-d-flex gx-flex-column">
                          <div className="gx-d-flex feedback-student">
                            <p className="feedback-A gx-mr-3">
                              <span className="feedback A gx-text-center">
                                A
                              </span>{" "}
                              - Understood Completely{" "}
                            </p>
                            <p className="feedback-B">
                              <span className="feedback B gx-text-center">
                                B
                              </span>{" "}
                              - I have some doubts
                            </p>
                          </div>
                          <div className="gx-d-flex feedback-student">
                            <p className="feedback-C ">
                              <span className="feedback C gx-text-center">
                                {" "}
                                C
                              </span>{" "}
                              - I want a revision
                            </p>{" "}
                            <p className="feedback-D">
                              <span className="feedback D gx-text-center">
                                {" "}
                                D{" "}
                              </span>{" "}
                              - I don't understand anything
                            </p>
                          </div>
                        </div>
                        {courseState.getCourseByAdmissionId.data.length === 1 &&
                          !courseState.getCourseByAdmissionId.data[0].hasOwnProperty(
                            "type"
                          ) && (
                            <h5>
                              {courseState.getCourseByAdmissionId.data[0]?.name}
                            </h5>
                          )}
                        {courseState.getCourseByAdmissionId.data?.map(
                          (courseData: getCourseByAdmissionIdResponse) =>
                            courseData?.admission_subcourses?.map(
                              (
                                subCourseData: AdmissionSubcourse,
                                index: number
                              ) => {
                                return (
                                  <>
                                    {subCourseData?.course_category ===
                                      CourseCategoryStatusType.SINGLE &&
                                    subCourseData?.package_id === null &&
                                    courseData.type ===
                                      CourseCategoryStatusType.SINGLE ? (
                                      <h5>
                                        {" "}
                                        {courseData?.name
                                          ? courseData?.name
                                          : subCourseData?.subcourse.name}
                                      </h5>
                                    ) : (
                                      ""
                                    )}
                                    <div
                                      className="course-detials"
                                      onClick={() =>
                                        batchsign(subCourseData?.batch_id)
                                      }
                                    >
                                      <Collapse
                                        accordion
                                        activeKey={activeKey || []}
                                        onChange={handlePanelChange}
                                        className={`${subCourseData?.subcourse_status}`}
                                      >
                                        <Panel
                                          header={
                                            <>
                                              <span>{index + 1}.</span>
                                              <span>
                                                {subCourseData?.subcourse?.name
                                                  ? `${
                                                      subCourseData?.subcourse
                                                        ?.name
                                                    }${
                                                      subCourseData?.batch
                                                        ? ` - (${subCourseData?.batch?.name})`
                                                        : ""
                                                    }`
                                                  : `${courseData?.name} ${
                                                      subCourseData?.batch
                                                        ? ` - (${subCourseData?.batch?.name})`
                                                        : ""
                                                    }`}
                                              </span>
                                            </>
                                          }
                                          key={
                                            subCourseData?.batch_id?.toString() ||
                                            index.toString()
                                          }
                                        >
                                          {batchState &&
                                            batchState.map(
                                              (
                                                batchDetails: IGetBatchData,
                                                batchStateIndex
                                              ) => {
                                                if (
                                                  subCourseData?.batch_id ===
                                                  batchDetails?.id
                                                ) {
                                                  return batchDetails?.data
                                                    ?.sort(
                                                      (
                                                        previousTopic,
                                                        nextTopic
                                                      ) =>
                                                        parseFloat(
                                                          previousTopic.sequence
                                                        ) -
                                                        parseFloat(
                                                          nextTopic.sequence
                                                        )
                                                    )
                                                    .map(
                                                      (
                                                        batchTopicData: getBatchByAdmissionIdResponse,
                                                        batchDataIndex: number
                                                      ) => {
                                                        if (
                                                          batchTopicData?.batchSingingSheets
                                                        ) {
                                                          return (
                                                            <>
                                                              <div className="gx-table-responsive">
                                                                <table>
                                                                  <thead>
                                                                    <tr>
                                                                      <th
                                                                        style={{
                                                                          width:
                                                                            "50px",
                                                                          display:
                                                                            "inline-block",
                                                                        }}
                                                                      >
                                                                        No.
                                                                      </th>
                                                                      <th
                                                                        className="gx-text-left"
                                                                        style={{
                                                                          width:
                                                                            "65%",
                                                                        }}
                                                                      >
                                                                        Topics
                                                                      </th>
                                                                      <th
                                                                        style={{
                                                                          width:
                                                                            "15%",
                                                                        }}
                                                                      >
                                                                        Date
                                                                      </th>

                                                                      <th
                                                                        style={{
                                                                          width:
                                                                            "15%",
                                                                        }}
                                                                      >
                                                                        Feedback/Marks
                                                                      </th>
                                                                    </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                    <tr className="main-topic">
                                                                      <td
                                                                        style={{
                                                                          width:
                                                                            "50px",
                                                                          display:
                                                                            "inline-block",
                                                                        }}
                                                                      >
                                                                        {batchDataIndex +
                                                                          1}
                                                                      </td>
                                                                      <td
                                                                        style={{
                                                                          width:
                                                                            "65%",
                                                                        }}
                                                                        className="gx-text-left"
                                                                      >
                                                                        {(batchTopicData.type ===
                                                                          TopicType.PROJECT ||
                                                                          batchTopicData.type ===
                                                                            TopicType.EXAM_PRACTICAL) &&
                                                                        batchData.data.find(
                                                                          (
                                                                            item: BatchDataItem
                                                                          ) =>
                                                                            item.id ===
                                                                              batchState[
                                                                                batchStateIndex
                                                                              ]
                                                                                .id &&
                                                                            item.batches_status !==
                                                                              UserBatchStatusType?.COMPLETED
                                                                        ) &&
                                                                        subCourseData.subcourse_status !=
                                                                          UserBatchStatusType?.COMPLETED &&
                                                                        batchTopicData?.Batch_student_marks?.find(
                                                                          (
                                                                            studentMarkData: BatchStudentMarks
                                                                          ) =>
                                                                            studentMarkData?.admission_id ===
                                                                            Number(
                                                                              id
                                                                            )
                                                                        )
                                                                          ?.marks ===
                                                                          null ? (
                                                                          <div className="gx-d-flex gx-align-items-center gx-justify-content-between ">
                                                                            <div>
                                                                              <b>
                                                                                {
                                                                                  batchTopicData?.name
                                                                                }
                                                                              </b>
                                                                            </div>
                                                                            <div>
                                                                              <button
                                                                                className="submit-btn"
                                                                                style={{
                                                                                  display:
                                                                                    "inline-block",
                                                                                  padding:
                                                                                    "3px",
                                                                                  background:
                                                                                    "transparent",
                                                                                  border:
                                                                                    "0",
                                                                                  color:
                                                                                    "#f44336",
                                                                                  borderBottom:
                                                                                    "1px solid #f44336",
                                                                                  paddingBottom:
                                                                                    "0",
                                                                                }}
                                                                                onClick={() =>
                                                                                  submissionLinkModel(
                                                                                    batchTopicData?.Batch_student_marks
                                                                                      ? batchTopicData?.Batch_student_marks
                                                                                      : []
                                                                                  )
                                                                                }
                                                                              >
                                                                                {isStudentMobileNumber() && (
                                                                                  <>
                                                                                    {batchTopicData?.Batch_student_marks?.filter(
                                                                                      (
                                                                                        markData: BatchStudentMarks
                                                                                      ) =>
                                                                                        markData?.admission_id ===
                                                                                        Number(
                                                                                          id
                                                                                        )
                                                                                    )?.some(
                                                                                      (
                                                                                        markData: BatchStudentMarks
                                                                                      ) =>
                                                                                        markData?.submission_link
                                                                                    )
                                                                                      ? "Submitted"
                                                                                      : "Submission"}
                                                                                  </>
                                                                                )}
                                                                              </button>
                                                                            </div>
                                                                          </div>
                                                                        ) : (
                                                                          <b>
                                                                            {
                                                                              batchTopicData?.name
                                                                            }
                                                                          </b>
                                                                        )}
                                                                      </td>
                                                                      <td></td>
                                                                      <td
                                                                        style={{
                                                                          whiteSpace:
                                                                            "nowrap",
                                                                        }}
                                                                      >
                                                                        {showMarks(
                                                                          batchTopicData
                                                                        )}
                                                                      </td>
                                                                    </tr>
                                                                    {batchTopicData?.batchSingingSheets
                                                                      ?.sort(
                                                                        (
                                                                          previousSingingSheetsDetails,
                                                                          nextSingingSheetsDetails
                                                                        ) =>
                                                                          parseFloat(
                                                                            previousSingingSheetsDetails.sequence
                                                                          ) -
                                                                          parseFloat(
                                                                            nextSingingSheetsDetails.sequence
                                                                          )
                                                                      )
                                                                      .map(
                                                                        (
                                                                          singingSheetData: BatchSingingSheet,
                                                                          singingSheetIndex: number
                                                                        ) => (
                                                                          <>
                                                                            <tr>
                                                                              <td
                                                                                style={{
                                                                                  width:
                                                                                    "50px",
                                                                                }}
                                                                              >
                                                                                {batchDataIndex +
                                                                                  1}

                                                                                .
                                                                                {singingSheetIndex +
                                                                                  1}
                                                                              </td>
                                                                              <td
                                                                                style={{
                                                                                  width:
                                                                                    "55%",
                                                                                }}
                                                                                className="gx-text-left"
                                                                              >
                                                                                {
                                                                                  <div className="gx-d-flex gx-align-items-center">
                                                                                    <div
                                                                                      dangerouslySetInnerHTML={{
                                                                                        __html:
                                                                                          singingSheetData?.description.replace(
                                                                                            "/n",
                                                                                            ""
                                                                                          ),
                                                                                      }}
                                                                                    ></div>
                                                                                    {(batchTopicData.type ===
                                                                                      TopicType.PROJECT ||
                                                                                      batchTopicData.type ===
                                                                                        TopicType.VIVA) &&
                                                                                      batchTopicData?.Batch_student_marks?.some(
                                                                                        (
                                                                                          studentMarkData: BatchStudentMarks
                                                                                        ) =>
                                                                                          studentMarkData?.remarks
                                                                                      ) && (
                                                                                        <div className="action-icon gx-ml-2">
                                                                                          <div
                                                                                            onClick={() => {
                                                                                              setRemarksModalOpen(
                                                                                                true
                                                                                              );
                                                                                              setShowRemarks(
                                                                                                batchTopicData?.Batch_student_marks?.find(
                                                                                                  (
                                                                                                    studentMarkData: BatchStudentMarks
                                                                                                  ) =>
                                                                                                    studentMarkData?.admission_id ===
                                                                                                    Number(
                                                                                                      id
                                                                                                    )
                                                                                                )
                                                                                                  ?.remarks
                                                                                              );
                                                                                            }}
                                                                                          >
                                                                                            <Text
                                                                                              ellipsis={
                                                                                                true
                                                                                              }
                                                                                              style={{
                                                                                                cursor:
                                                                                                  "pointer",
                                                                                                color:
                                                                                                  "#fff",
                                                                                              }}
                                                                                            >
                                                                                              <EyeOutlined
                                                                                                style={{
                                                                                                  color:
                                                                                                    "#fff",
                                                                                                }}
                                                                                              />
                                                                                            </Text>
                                                                                          </div>
                                                                                        </div>
                                                                                      )}
                                                                                    {singingSheetData?.notes && (
                                                                                      <MoreButtonShow
                                                                                        text={
                                                                                          singingSheetData?.notes ||
                                                                                          ""
                                                                                        }
                                                                                        titleText={
                                                                                          "Notes"
                                                                                        }
                                                                                      />
                                                                                    )}
                                                                                  </div>
                                                                                }
                                                                              </td>
                                                                              <td>
                                                                                <table>
                                                                                  <tbody>
                                                                                    {singingSheetData?.batchFacultyAttendances?.map(
                                                                                      (
                                                                                        facultyAttendanceData: BatchFacultyAttendance
                                                                                      ) => (
                                                                                        <tr
                                                                                          key={
                                                                                            facultyAttendanceData?.id
                                                                                          }
                                                                                        >
                                                                                          <td
                                                                                            style={{
                                                                                              whiteSpace:
                                                                                                "nowrap",
                                                                                            }}
                                                                                          >
                                                                                            <>
                                                                                              {dateFormate(
                                                                                                facultyAttendanceData?.actual_date
                                                                                              )}
                                                                                            </>
                                                                                          </td>
                                                                                        </tr>
                                                                                      )
                                                                                    )}
                                                                                  </tbody>
                                                                                </table>
                                                                              </td>
                                                                              <td>
                                                                                <table>
                                                                                  <tbody>
                                                                                    {singingSheetData?.batchFacultyAttendances?.map(
                                                                                      (
                                                                                        facultyAttendanceData: BatchFacultyAttendance
                                                                                      ) => (
                                                                                        <tr
                                                                                          key={
                                                                                            facultyAttendanceData?.id
                                                                                          }
                                                                                        >
                                                                                          <td className="gx-p-0">
                                                                                            {feedbackOrMarks(
                                                                                              batchTopicData,
                                                                                              singingSheetData.id,
                                                                                              facultyAttendanceData.id
                                                                                            )}
                                                                                          </td>
                                                                                        </tr>
                                                                                      )
                                                                                    )}
                                                                                  </tbody>
                                                                                </table>
                                                                              </td>
                                                                            </tr>
                                                                          </>
                                                                        )
                                                                      )}
                                                                  </tbody>
                                                                </table>
                                                              </div>
                                                            </>
                                                          );
                                                        }
                                                      }
                                                    );
                                                }
                                              }
                                            )}
                                        </Panel>
                                      </Collapse>
                                    </div>
                                  </>
                                );
                              }
                            )
                        )}
                      </>
                    </div>
                  </div>
                </Panel>
              </Collapse>
            </Card>
          </Col>
          <Col xs={24} xl={12}>
            <Card className="student-details gx-mt-2">
              <Collapse defaultActiveKey={["1"]}>
                <Panel header="Fees Details" key="1">
                  {sortedData.length > 0 ? (
                    <div className="gx-table-responsive">
                      <table className="gx-mt-0">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>Installment Date</th>
                            <th>Installment Amount</th>
                            <th>Due Amount</th>
                            <th>Paid Amount</th>
                            <th>Paid Date</th>
                            <th>Paid Status</th>
                            <th>Mode</th>
                          </tr>
                        </thead>
                        <tbody>
                          <>
                            {sortedData?.map((installmentDetails) => (
                              <tr key={installmentDetails.id}>
                                <td>{installmentDetails?.installment_no}</td>
                                <td>
                                  {dateFormate(
                                    installmentDetails?.installment_date
                                  )}
                                </td>
                                <td>
                                  {currencyFormat(
                                    Number(
                                      installmentDetails?.installment_amount
                                    )
                                  )}
                                </td>
                                <td>
                                  <i className="fa fa-rupee gx-mr-1"></i>
                                  {installmentDetails?.due_amount
                                    ? currencyFormat(
                                        Number(installmentDetails?.due_amount)
                                      )
                                    : "0.00"}
                                </td>
                                <td>
                                  <i className="fa fa-rupee gx-mr-1"></i>
                                  {installmentDetails?.pay_amount
                                    ? currencyFormat(
                                        Number(installmentDetails?.pay_amount)
                                      )
                                    : "0.00"}
                                </td>
                                <td>
                                  {dateFormate(installmentDetails?.pay_date)}
                                </td>
                                <td>
                                  <span
                                    className={
                                      installmentDetails?.status === "PAID"
                                        ? "paid"
                                        : "unpaid"
                                    }
                                  >
                                    {installmentDetails?.status.toLowerCase()}
                                  </span>
                                </td>
                                <td>
                                  {installmentDetails?.payment_mode?.toLocaleLowerCase()}
                                </td>
                              </tr>
                            ))}
                          </>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="no-data">
                      <InboxOutlined />
                      No Data
                    </div>
                  )}
                </Panel>
              </Collapse>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        title="Topic Feedback"
        width={"760px"}
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={false}
      >
        <div className="topicFeedback">
          <>
            {topicData && (
              <h4
                dangerouslySetInnerHTML={{
                  __html: topicData.description,
                }}
              ></h4>
            )}
            <div>
              {
                <span className="gx-mt-0">
                  {dateFormate(moment(latestActualDate).format("YYYY-MM-DD"))}
                </span>
              }
            </div>
          </>
        </div>

        <Form id="myForm" onFinish={onFinish} form={form}>
          <Form.Item
            className="mainTopicFeedback"
            name="feedback"
            rules={rules.feedback}
          >
            <Radio.Group name="feedback">
              <Radio className="feedback-value" value={"A"}>
                A
                <span className="feedback-values fs-1">
                  Understood Completely
                </span>
              </Radio>

              <Radio className="feedback-value" value={"B"}>
                B
                <span className="feedback-values fs-1">I have some doubts</span>
              </Radio>
              <Radio className="feedback-value" value={"C"}>
                C<span className="feedback-values fs-1">I want a revision</span>
              </Radio>
              <Radio className="feedback-value" value={"D"}>
                D
                <span className="feedback-values fs-1">
                  I don't understand anything
                </span>
              </Radio>
            </Radio.Group>
          </Form.Item>
          <FloatLabel
            label="Remarks"
            placeholder="Enter Remarks"
            name="remarks"
          >
            <Form.Item name="remarks">
              <Input name="remarks" />
            </Form.Item>
          </FloatLabel>
          <div className="gx-text-right">
            <Button className="gx-mb-0" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              className="btn-submit gx-mb-0"
              htmlType="submit"
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
      {isSubmissionModel && (
        <ModalComponent
          component={
            <SubmissionForm
              onFinish={onSubmitForm}
              initialValue={initialValue}
              setIsSubmissionModel={setIsSubmissionModel}
            />
          }
          showModal={isSubmissionModel}
          onCancel={() => setIsSubmissionModel(false)}
          footer={false}
          width={500}
          className="close-button-modal"
        ></ModalComponent>
      )}
      {remarksModalOpen && (
        <ModalComponent
          className=""
          title="Remark"
          showModal={remarksModalOpen}
          onCancel={() => {
            setRemarksModalOpen(false);
          }}
          component={
            <>
              {showRemark ? (
                <p className="gx-mr-1">
                  <span dangerouslySetInnerHTML={{ __html: showRemark }} />
                </p>
              ) : (
                <p>No Data</p>
              )}
            </>
          }
          footer={[
            <div>
              <Button key="back" onClick={() => setRemarksModalOpen(false)}>
                Cancel
              </Button>
            </div>,
          ]}
        ></ModalComponent>
      )}
    </>
  );
};

export default StudentDetail;
